/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

body {
  font-family: "Poppins", sans-serif;
  background-color: #171717;
  
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  padding: 20px 50px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.nav-links {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.nav-links li {
  color: white;
  padding: 0px 25px;
  position: relative;
}

/* css for table */
.autnh-1 {
  padding: 16px;
  font-size: 11px;
  font-weight: 500;
  color: white;
  text-align: center;
  vertical-align: middle;
}

.autnr {
  padding: 10px;
  font-size: 11px;
  font-weight: 200;
  color: white;
  background-color: #3b3b3b;
  text-align: center;
  vertical-align: middle;
}

.mis-head{
  height: 3rem;
  padding: 0px 20px;
  font-size: 11px;
  font-weight: 500;
  color: white;
  text-align: center;
  vertical-align: middle;
}

.mis-data{
  height: 3rem;
  padding: 6px 10px;
  font-size: 11px;
  font-weight: 200;
  color: white;
  background-color: #3b3b3b;
  text-align: center;
  vertical-align: middle;
}

.metasea-info {
  font-size: 14px;
  font-weight: 100;
}

/* Logo rotation css */
.rotate-logo {
  animation: rotateY 4s linear infinite;
  transform-origin: center;
}

@keyframes rotateY {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}

.scrollable-table {
  max-height: 50vh;
  overflow-y: auto;
}

/* WebKit-based browsers (Chrome, Safari) */
.scrollable-table::-webkit-scrollbar {
  width: 12px;
}

.scrollable-table::-webkit-scrollbar-track {
  background-color: black;
}

.scrollable-table::-webkit-scrollbar-thumb {
  background-color: #ff6c2f;
  border-radius: 10px;
  border: 3px solid black;
}

/* Firefox scrollbar styling */
.scrollable-table {
  scrollbar-width: thin;
  scrollbar-color: #ff6c2f black;
}

.scrollable-table::-webkit-scrollbar-corner,
.scrollable-table::-webkit-resizer {
  border-radius: 10px;
}

/* Edge and IE */
.scrollable-table::-ms-scrollbar-thumb {
  background-color: #ff6c2f;
  border-radius: 10px;
  border: 2px solid black;
}

.gap-row {
  height: 0.5rem; /* Adjust as needed */
  background-color: transparent; /* Or any other color */
}

.table-data {
  overflow-x: scroll;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #FF6C2F #2C2C2C; /* For Firefox */
}

.table-data::-webkit-scrollbar {
  height: 12px; /* Adjust scrollbar height */
  background-color: #2C2C2C; /* Background color of the scrollbar */
}

.table-data::-webkit-scrollbar-thumb {
  background-color: #FF6C2F; /* Color of the scroll thumb */
  border-radius: 10px; /* Round the scroll thumb edges */
}

.table-data::-webkit-scrollbar-thumb:hover {
  background-color: #c75525; /* Thumb color on hover */
}

.media-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Optional: semi-transparent background */
}

.player-wrapper {
  position: relative;
  background-color: #000; /* Dark background for the media player */
  padding: 50px;
  border-radius: 10px;
}

.close-button {
  position: absolute;
  top: 3px;
  right: 10px;
  padding: 10px;
  /* background-color: red; */
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 80%;
  cursor: pointer;
}

.close-button:hover {
  background-color: darkred;
}
